.podcastProviderImg {
    width: 36px !important;
    min-width: 36px;
    height: 36px;
    box-shadow: 1px 1px 6px #00000026;
    border-radius: 50%!important;
    vertical-align: middle;
    cursor: pointer;
}

.podcastProviderImgContainer {
    padding-inline: 0.2rem;
}

@media only screen and (max-width: 600px) {
    .podcastProviderImgContainer {
        padding-inline: 0.2rem;
        position: relative;
        padding-top: 2rem;
    }

}