.overlay {
    pointer-events: none;
}

.episodeCard {
    margin: '7rem'
}

.card-padded {
    margin: 5rem;
}