

.mainBannerWrapper > img{
    vertical-align: top;
    width: 100%; /* max width */
    opacity: 0;  /* make it transparent */
}

.mainBannerWrapper > div{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.bannerRow {
    height: 33%;
    padding-top: 1rem;
}

.bannerHeading {
    color: white;
}

  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .mainBannerWrapper{
        position: relative;
        background: no-repeat url(../images/mobileBackground.png) 50% / 100%;
        padding-top: 89%;
        width: 100%;
        max-height: 130px;
    }

    .headingContainer {
        text-align: center;
        
    }
    
    .contactUsContainer {
        text-align: center;
        
    }
    
    .listenOnContainer {
        display: flex;
        justify-content: center;   
        height: 100%;
    }

    .listenOnText {
        color: white;
        font-weight: 400;
        font-size: larger;
        position: absolute;
    }

    .text {
        color: white;
        font-weight: 400;
        font-size: larger;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .mainBannerWrapper{
        position: relative;
        background: no-repeat url(../images/banner.png) 50% / 100%;
        padding-top: 34%;
        max-height: 180px;
    }

    .headingContainer {
        text-align: justify;
        padding-left: 9rem !important;
    }
    
    .contactUsContainer {
        text-align: justify;
        padding-left: 9rem !important;
    }
    
    .listenOnContainer {
        display: flex;
        justify-content: justify;
        padding-left: 9rem !important;
    }

    .listenOnText {
        color: white;
        font-weight: 400;
        font-size: larger;
    }

    .text {
        color: white;
        font-weight: 400;
        font-size: larger;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .mainBannerWrapper{
        position: relative;
        background: no-repeat url(../images/banner.png) 50% / 100%;
        padding-top: 34%;
        max-height: 450px;
    }

    .headingContainer {
        text-align: justify;
        padding-left: 7rem !important;
    }
    
    .contactUsContainer {
        text-align: justify;
        padding-left: 7rem !important;
    }
    
    .listenOnContainer {
        display: flex;
        justify-content: justify;
        padding-left: 7rem !important;
    }

    .listenOnText {
        color: white;
        font-weight: 400;
        font-size: larger;
    }
    
    .text {
        color: white;
        font-weight: 400;
        font-size: larger;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

    .mainBannerWrapper{
        position: relative;
        background: no-repeat url(../images/banner.png) 50% / 100%;
        padding-top: 34%;
        max-height: 450px;
    }

    .headingContainer {
        text-align: justify;
        padding-left: 18rem !important;
    }
    
    .contactUsContainer {
        text-align: justify;
        padding-left: 18rem !important;
    }
    
    .listenOnContainer {
        display: flex;
        justify-content: justify;
        padding-left: 18rem !important;
    }

    .listenOnText {
        color: white;
        font-weight: 400;
        font-size: larger;
    }
    
    .text {
        color: white;
        font-weight: 400;
        font-size: larger;
    }

}